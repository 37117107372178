<template>
    <div>
        <transition name="fade" v-if="chartData.length > 0">
        <v-card>
          <v-card-title>
                    <span class="body-2"><v-icon left>mdi-chart-line</v-icon>{{  chartTitle }}</span>
                    <v-spacer></v-spacer>
                    <v-btn icon @click="card1 = !card1">
                        <v-icon>{{ card1 ? 'mdi-chevron-down' : 'mdi-chevron-up' }}</v-icon>
                    </v-btn>
            </v-card-title>
            <v-divider v-show="card1"></v-divider>
            <v-card-text v-show="card1">
                <apexchart type="area" height="450" :options="getChartOptions" :series="series"></apexchart>
            </v-card-text>
        </v-card>
    </transition>
    </div>
</template>

<script>

import { EventBus } from '@/main.js'
import VueApexCharts from 'vue-apexcharts'
import { getCurrentDate, getCurrentTime, getCurrentDateTime } from '@/helpers/utilities'

export default {
    name: 'revenue-sparkline',
    components: {
        apexchart: VueApexCharts,
    },

    data: () =>  ({
      card1: true,
      revenue: "",
      chartData: [],
      dataLabelsEnabled: true,
      chartTitle: "Total revenue",

      series: [
        {
          //data: [30, 40, 25, 50, 49, 21, 70, 51, 60, 10, 30, 40, 25, 50, 49, 21, 70, 51, 60, 10, 30, 40, 25, 50, 49, 21, 70, 51, 60, 10, 30, 40, 25, 50, 49, 21, 70, 51, 60, 10],
          data: [
            /*[new Date('2023-01-01').getTime(), 30],
            [new Date('2023-01-02').getTime(), 40],
            [new Date('2023-01-03').getTime(), 25],
            [new Date('2023-01-04').getTime(), 50],
            [new Date('2023-01-05').getTime(), 49],
            [new Date('2023-01-06').getTime(), 21],
            [new Date('2023-01-07').getTime(), 70],
            [new Date('2023-01-08').getTime(), 51],
            [new Date('2023-01-09').getTime(), 60],
            [new Date('2023-01-10').getTime(), 10],
            [new Date('2023-01-11').getTime(), 22],
            [new Date('2023-01-12').getTime(), 34],
            [new Date('2023-01-13').getTime(), 70],
            [new Date('2023-01-14').getTime(), 95],
            [new Date('2023-01-15').getTime(), 33],
            [new Date('2023-01-16').getTime(), 45],
            [new Date('2023-01-17').getTime(), 49],
            [new Date('2023-01-18').getTime(), 90],
            [new Date('2023-01-19').getTime(), 110],
            [new Date('2023-01-20').getTime(), 100],
            [new Date('2023-01-21').getTime(), 110],
            [new Date('2023-01-22').getTime(), 300],
            [new Date('2023-01-23').getTime(), 100],
            [new Date('2023-01-24').getTime(), 40],
            [new Date('2023-01-25').getTime(), 200],
            [new Date('2023-01-26').getTime(), 300],
            [new Date('2023-01-27').getTime(), 440],
            [new Date('2023-01-28').getTime(), 800],
            [new Date('2023-01-29').getTime(), 900],
            [new Date('2023-01-30').getTime(), 100],
            [new Date('2023-02-01').getTime(), 35],
            [new Date('2023-02-02').getTime(), 135],
            [new Date('2023-02-03').getTime(), 235],
            [new Date('2023-02-04').getTime(), 335],
            [new Date('2023-02-05').getTime(), 220],
            [new Date('2023-02-06').getTime(), 220],
            [new Date('2023-02-07').getTime(), 220],
            [new Date('2023-02-08').getTime(), 220],
            [new Date('2023-02-09').getTime(), 220],
            [new Date('2023-02-10').getTime(), 220],
            [new Date('2023-02-11').getTime(), 120],
            [new Date('2023-02-12').getTime(), 320],
            [new Date('2023-02-13').getTime(), 520],
            [new Date('2023-02-14').getTime(), 720],
            [new Date('2023-02-15').getTime(), 820],
            [new Date('2023-02-16').getTime(), 420],
            [new Date('2023-02-17').getTime(), 20],
            [new Date('2023-02-18').getTime(), 120],
            [new Date('2023-02-19').getTime(), 420],
            [new Date('2023-02-20').getTime(), 520],
            [new Date('2023-02-21').getTime(), 620],
            [new Date('2023-02-22').getTime(), 720],
            [new Date('2023-02-23').getTime(), 120],
            [new Date('2023-02-24').getTime(), 120],
            [new Date('2023-02-25').getTime(), 220],
            */
          ]
        },
      ],
  }),

  computed: {
    getChartOptions() {
      return {
        title: {
              text: "",
              offsetX: 0,
              style: {
                fontSize: '14px',
                fontWeight: '400'
              }
            },
            subtitle: {
              //text: 'Revenue',
              text: '',
              offsetX: 0,
              style: {
                fontSize: '14px',
              }
        },
        chart: {
          /*sparkline: {
            enabled: true,
          },
          */
          type: 'area'
        },
        dataLabels: {
          enabled:this.dataLabelsEnabled, // Enable data labels by default
          formatter: function(val, opts) {
              //window.console.log(opts)
            return val + " €";
          }
        },
        stroke: {
          curve: 'smooth',
          //curve: 'straight',
        },
        markers: {
          size: 0,
        },
        xaxis: {
          crosshairs: {
            width: 1,
          },
          type: 'datetime'
        },
        yaxis: {
            opposite: true
        },
        fill: {
            opacity: 0.3
        },
        tooltip: {
          fixed: {
            enabled: false,
          },
          x: {
            show: false,
            //format: 'dd MMM yyyy'
          },
          y: {
            title: {
              formatter: function (seriesName) {
                return 'Total amount (€):';
              },
            },
          },
          marker: {
            show: false,
          },
        },
      }
    }
  },

  methods: {
  },

  created() {
        let vm = this;
        EventBus.$on('widgets-data', (data) => {
          //window.console.log("### RevenuSparkline.vue@widgets-data [EventBus listener] ###");

          //window.console.log(getCurrentDateTime())

          //window.console.log(data);
          //window.console.log(data.total_amount)
          //window.console.log(data.data.total_revenue)

            let payload = data

            //window.console.log(payload.parameters)

            if(payload.parameters.startDate !== payload.parameters.endDate) {
              vm.chartTitle = "Total revenue (" + payload.parameters.startDate + " - " + payload.parameters.endDate +")"
            } else {
              vm.chartTitle = "Total revenue - " + payload.parameters.startDate +  " (" + payload.parameters.startTime + " - " + payload.parameters.endTime +")"
            }

            vm.series = [{
                data: []
            }]

            let seriesData = []
            payload.data.total_revenue.forEach(item => {

              //window.console.log(item[1])
              //window.console.log(new Date(item[0]).getTime());

              let itemTimestamp = new Date(item[0]);
              itemTimestamp.setUTCHours(itemTimestamp.getUTCHours() + 1)

              const miliseconds = itemTimestamp.getTime();
              
              let sd = Array();
              sd.push(miliseconds)
              //sd.push((new Date(item[0]).getTime()))
              sd.push(item[1])

              //window.console.log(sd)

              seriesData.push(sd)
              sd = null;
              
            }) 

            this.chartData = seriesData;
            
            vm.revenue = data.total_amount;

            vm.series = [{
                data: seriesData
            }]

            if(seriesData.length > 90) {
              vm.dataLabelsEnabled = false
            } else {
              vm.dataLabelsEnabled = true
            }

            //window.console.log(vm.series)
        })
    },

    mounted() {
    },

    beforeDestroy() {
        EventBus.$off('widgets-data'); 
    }
}

</script>

<style scoped>
    .fade-enter-active, .fade-leave-active {
        transition-property: opacity;
        transition-duration: .25s;
    }

    .fade-enter-active {
        transition-delay: .25s;
    }

    .fade-enter, .fade-leave-active {
        opacity: 0
    }
</style>